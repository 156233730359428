// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ComplexStatisticsCard from "../../components/Cards/StatisticsCards/ComplexStatisticsCard";
// Dashboard components
import { useEffect, useState } from "react";
import DashboardEndpoint from "../../services/dashboard/dashboard.endpoint";
import { useKeycloak } from "@react-keycloak/web";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import DataTable from "../../components/Tables/DataTable";
import MDButton from "../../components/MDButton";
import { formatHumanReadableDate } from "../../utils/static/DateUtils";
import { Link, useNavigate } from "react-router-dom";
import { config } from "../../Constants";

function Dashboard() {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation([], { useSuspense: false });
  const navigate = useNavigate();

  const [userTotalLessonCount, setUserTotalLessonCount] = useState(0);
  const [userOngoingLessonCount, setUserOngoingLessonCount] = useState(0);
  const [userTotalCompletedLessonCount, setUserTotalCompletedLessonCount] =
    useState(0);
  const [userPlannedLessonCount, setUserPlannedLessonCount] = useState(0);
  const [userCompletedLessons, setUserCompletedLessons] = useState([]);
  const [userOngoingLessons, setUserOngoingLessons] = useState([]);
  const [userPlannedLessons, setUserPlannedLessons] = useState([]);

  useEffect(() => {
    DashboardEndpoint.userTotalLessonCount(keycloak.token)
      .then((response) => {
        setUserTotalLessonCount(response);
      })
      .catch((error) => {
        toast.error("Failed to fetch total lesson count");
      });
  }, []);

  useEffect(() => {
    DashboardEndpoint.userOngoingLessonCount(keycloak.token)
      .then((response) => {
        setUserOngoingLessonCount(response);
      })
      .catch((error) => {
        toast.error("Failed to fetch user ongoing lesson count");
      });
  }, []);

  useEffect(() => {
    DashboardEndpoint.userTotalCompletedLessonCount(keycloak.token)
      .then((response) => {
        setUserTotalCompletedLessonCount(response);
      })
      .catch((error) => {
        toast.error("Failed to fetch user total completed lesson count");
      });
  }, []);

  useEffect(() => {
    DashboardEndpoint.userPlannedLessonCount(keycloak.token)
      .then((response) => {
        setUserPlannedLessonCount(response);
      })
      .catch((error) => {
        toast.error("Failed to fetch user planned lesson count");
      });
  }, []);

  useEffect(() => {
    DashboardEndpoint.userCompletedLessons(keycloak.token)
      .then((response) => {
        setUserCompletedLessons(response);
      })
      .catch((error) => {
        toast.error("Failed to fetch user completed lessons");
      });
  }, []);

  useEffect(() => {
    DashboardEndpoint.userOngoingLessonsTop5(keycloak.token)
      .then((response) => {
        setUserOngoingLessons(response);
      })
      .catch((error) => {
        toast.error("Failed to fetch user completed lessons");
      });
  }, []);

  useEffect(() => {
    DashboardEndpoint.userPlannedLessonsTop5(keycloak.token)
      .then((response) => {
        setUserPlannedLessons(response);
      })
      .catch((error) => {
        toast.error("Failed to fetch user completed lessons");
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="science"
                title="Total Lessons Count"
                count={userTotalLessonCount}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="beenhere"
                title="Completed Lessons Count"
                count={userTotalCompletedLessonCount}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="delivery_dining"
                title="Ongoing Lessons Count"
                count={userOngoingLessonCount}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="event"
                title="Planned Lessons Count"
                count={userPlannedLessonCount}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <Card>
                <MDBox
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  p={3}
                >
                  <MDBox>
                    <MDTypography gutterBottom variant="h6">
                      {t("Completed Lessons")}
                    </MDTypography>
                  </MDBox>
                  <MDBox color="text" px={2}>
                    <MDButton
                      size="small"
                      variant="outlined"
                      color="info"
                      startIcon={<Icon> visibility </Icon>}
                      onClick={() =>
                        navigate(config.routes.completed_lessons.route)
                      }
                    >
                      {t("View all")}
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <DataTable
                    entriesPerPage={false}
                    isSorted={false}
                    noEndBorder
                    showTotalEntries={false}
                    table={{
                      columns: [
                        {
                          Header: t("NAME"),
                          align: "center",
                          Cell: ({ row }) => {
                            return (
                              <Link
                                to={`${config.routes.courses.route}/${row.original.courseId}/lessons/${row.original.lesson.id}`}
                                state={{
                                  path: row.original.lesson.name,
                                  rootPath: row.original.courseId,
                                }}
                              >
                                {row.original.lesson.name}
                              </Link>
                            );
                          },
                        },
                        {
                          Header: t("COMPLETED DATE"),
                          accessor: "completionDate",
                          align: "center",
                          Cell: ({ row }) =>
                            formatHumanReadableDate(
                              row.original.completionDate,
                            ),
                        },
                      ],
                      rows: userCompletedLessons || [],
                    }}
                  />
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <Card>
                <MDBox
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  p={3}
                >
                  <MDBox>
                    <MDTypography gutterBottom variant="h6">
                      {t("Ongoing Lessons")}
                    </MDTypography>
                  </MDBox>
                  <MDBox color="text" px={2}>
                    <MDButton
                      size="small"
                      variant="outlined"
                      color="info"
                      startIcon={<Icon> visibility </Icon>}
                      onClick={() =>
                        navigate(config.routes.ongoing_lessons.route)
                      }
                    >
                      {t("View all")}
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <DataTable
                    entriesPerPage={false}
                    isSorted={false}
                    noEndBorder
                    showTotalEntries={false}
                    table={{
                      columns: [
                        {
                          Header: t("NAME"),
                          accessor: "name",
                          align: "center",
                          Cell: ({ row }) => {
                            return (
                              <Link
                                to={`${config.routes.courses.route}/${row.original.courseId}/lessons/${row.original.id}`}
                                state={{
                                  path: row.original.name,
                                  rootPath: row.original.courseId,
                                }}
                              >
                                {row.original.name}
                              </Link>
                            );
                          },
                        },
                      ],
                      rows: userOngoingLessons || [],
                    }}
                  />
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <Card>
                <MDBox
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  p={3}
                >
                  <MDBox>
                    <MDTypography gutterBottom variant="h6">
                      {t("Planned Lessons")}
                    </MDTypography>
                  </MDBox>
                  <MDBox color="text" px={2}>
                    <MDButton
                      size="small"
                      variant="outlined"
                      color="info"
                      startIcon={<Icon> visibility </Icon>}
                      onClick={() =>
                        navigate(config.routes.planned_lessons.route)
                      }
                    >
                      {t("View all")}
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <DataTable
                    entriesPerPage={false}
                    isSorted={false}
                    noEndBorder
                    showTotalEntries={false}
                    table={{
                      columns: [
                        {
                          Header: t("NAME"),
                          accessor: "name",
                          align: "center",
                          Cell: ({ row }) => {
                            return (
                              <Link
                                to={`${config.routes.courses.route}/${row.original.courseId}/lessons/${row.original.id}`}
                                state={{
                                  path: row.original.name,
                                  rootPath: row.original.courseId,
                                }}
                              >
                                {row.original.name}
                              </Link>
                            );
                          },
                        },
                        {
                          Header: t("CREATED DATE"),
                          accessor: "createdAt",
                          align: "center",
                          Cell: ({ row }) =>
                            formatHumanReadableDate(row.original.createdAt),
                        },
                      ],
                      rows: userPlannedLessons || [],
                    }}
                  />
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
